<template>
  <div id="edit" v-if="annuarioDBOpen && parametersOpen">
    <div class="surtitre" style="padding-top: 20px">
      <router-link :to="this.routerParams.urlBack">{{ this.routerParams.libelleBack }}</router-link>
    </div>
    <div class="titre-principal centre" style="padding-bottom: 20px">
      Edit di {{ nomeDaEditare(this.routerParams.noTab) }}
    </div>
    <form id="membriForm" @submit.prevent="handleSubmit()">

      <div id="top"></div>

      <!-- Nome e Cognome -->
      <div class="flex"> 
        <div class="formNome">
          <label for="nome">Nome <span class="red">*</span></label>
          <input type="text" id="nome" name="nome"
                  v-model="form.nome" 
                  @blur="validateField('nome')" >
          <div class="red">{{ errors.nome }}</div>
        </div>
        <div class="formCognome">
          <label for="cognome">Cognome <span class="red">*</span></label>
          <input type="text" id="cognome" name="cognome"
                  v-model="form.cognome" 
                  @blur="validateField('cognome')">
          <div class="red">{{ errors.cognome }}</div>
        </div>
      </div>

      <!-- Tipo e Luogo -->
      <div class="flex"> 
        <div class="formTipo">
          <label for="selectCategoria">Categoria</label><br>
          <select class="dropdown" id="selectCategoria">
            <template 
              v-for="(tipo, index) in this.$store.state.params['tipo_confessore']" 
                :key="index" 
            >
                <option :selected="form.tipo==index" :value="index">{{ tipo }}</option>
            </template>
          </select>
        </div>
        <div class="formLuogo">
          <label for="selectLuogo">Luogo abitazione</label><br>
          <select class="dropdown" id="selectLuogo">
            <template 
              v-for="(luogo, index) in this.$store.state.params['luogo']" 
                :key="index" 
                >
                <option :selected="form.luogo==index" :value="index">{{ luogo }}</option>
            </template>
          </select>
        </div>
      </div>

      <!-- Stanza e Telefono in stanza -->
      <div class="flex flex-wrap"> 
        <div class="formStanza">
          <label for="stanza">Stanza</label><br>
          <select class="dropdown" id="selectStanza">
            <template 
              v-for="(stanza, index) in this.$store.state.params['stanza']" 
                :key="index" 
                >
                <option :selected="form.stanza==index" :value="index">{{ index }} - {{ stanza.libelle }}</option>
            </template>
          </select>
        </div>
        <div class="formTel">
          <label for="tel">Telefono in stanza</label>
          <input type="text" id="tel" name="tel"
                  v-model="form.tel">
        </div>
      </div>

      <!-- E-mail e Cellulare -->
      <div class="flex"> 
        <div class="formEmail">
          <label for="email">Email</label>
          <input type="text" id="email" name="email"
                  v-model="form.email">
        </div>
        <div class="formCellulare">
          <label for="cellulare">Cellulare</label>
          <input type="text" id="cellulare" name="cellulare"
                  v-model="form.cellulare">
        </div>
      </div>

      <!-- Orari -->
      <div class="formOrari">
        <label for="orari">Orario confessioni</label><br>
        <textarea id="orari" name="orari" rows="7" class="orari"
                v-model="form.orari">
        </textarea>
      </div>

      <!-- Lingue -->
      <div class="formLingue">
        <div style="display: inline-block; font-weight: bold;">Lingue:&nbsp;</div>
        <template 
          v-for="(lingua, index) in this.$store.state.params['lingua']" 
            :key="index" 
            >
            <div style="display: inline-block;">
              <label :id="'lingua-' + index" :class="[this.form.lingue.includes(index) ? 'bold' : 'nonBold']" style="white-space: nowrap;">
                <input class="lingueBox" type="checkbox" style="vertical-align: middle; " 
                    :checked="this.form.lingue.includes(index)" 
                    :value="index"
                    @click="languageClick(index)"><span>{{ capitalizeFirstLetter(lingua) }}&nbsp;</span>
              </label>
            </div>
            
        </template>
      </div>

      <!-- Data di nascita, professione, ordinazione, onomastico -->
      <div class="flex flex-wrap"> 
        <div class="formNascita">
          <label for="nascita">Data di Nascita</label><br>
          <input  type="date" id="nascita" name="nascita"
                v-model="form.nascita">
        </div>
        <div class="formProfessione">
          <label for="professione">Professione</label><br>
          <input  type="date" id="professione" name="professione"
                v-model="form.professione">
        </div>
        <div class="formOrdinazione">
          <label for="ordinazione">Ordinazione</label><br>
          <input  type="date" id="ordinazione" name="ordinazione"
                v-model="form.ordinazione">
        </div>
        <div class="formOnomastico">
          <label for="onomastico">Onomastico (mm.aa)</label><br>
          <input  type="text" id="onomastico" name="onomastico"
                v-model="form.onomastico">
        </div>
      </div>

      <!-- Festa -->
      <div class="formFesta"> 
        <label for="festa">Festa:&nbsp;</label>
        <select class="dropdown" id="selectFesta">
          <template 
            v-for="(festa, index) in this.$store.state.params['festa']" 
              :key="index" 
              >
              <option :selected="form.festa==index" :value="index">{{ festa }}</option>
          </template>
        </select>
      </div>

      <!-- Data di arrivo, info di arrivo -->
      <div class="flex"> 
        <div class="formDataArrivo">
          <label for="data_arrivo">Data di Arrivo</label><br>
          <input  type="date" id="data_arrivo" name="data_arrivo"
                v-model="form.data_arrivo">
        </div>
        <div class="formInfoArrivo">
          <label for="info_arrivo">Info Arrivo</label><br>
          <input  type="text" id="info_arrivo" name="info_arrivo"
                v-model="form.info_arrivo">
        </div>
      </div>

      <!-- Data di partenza, info di partenza -->
      <div class="flex"> 
        <div class="formDataPartenza">
          <label for="data_partenza">Data di Partenza</label><br>
          <input  type="date" id="data_partenza" name="data_partenza"
                v-model="form.data_partenza">
        </div>
        <div class="formInfoPartenza">
          <label for="info_partenza">Info Partenza</label><br>
          <input  type="text" id="info_partenza" name="info_partenza"
                v-model="form.info_partenza">
        </div>
      </div>

      <!-- Telefono famiglia, estero, provincia -->
      <div class="flex flex-wrap"> 
        <div class="formTelFamiglia">
          <label for="telfamiglia">Telefono Famiglia</label><br>
          <input  type="text" id="telfamiglia" name="telfamiglia"
                v-model="form.telfamiglia">
        </div>
        <div class="formTelEstero">
          <label for="telestero">Telefono Estero</label><br>
          <input  type="text" id="telestero" name="telestero"
                v-model="form.telestero">
        </div>
        <div class="formTelProvincia">
          <label for="telprovincia">Telefono Provincia</label><br>
          <input  type="text" id="telprovincia" name="telprovincia"
                v-model="form.telprovincia">
        </div>
      </div>

      <!-- Giurisdizione -->
      <div class="formGiurisdizione">
        <label for="giurisdizione">Giurisdizione</label>
        <input type="text" id="giurisdizione" name="giurisdizione"
                v-model="form.giurisdizione">
      </div>

      <!-- Sito web -->
      <div class="formSitoweb">
        <label for="sitoweb">Sito web</label>
        <input type="text" id="sitoweb" name="sitoweb"
                v-model="form.sitoweb">
      </div>

      <!-- Attivo -->
      <div class="formAttivo">
        <label for="lingue">Attivo:&nbsp;</label>
        <input type="checkbox" id="attivo" name="attivo" value="Attivo" style="vertical-align: middle"
              :checked="this.form.attivo == 1">
      </div>

      <!-- Osservazioni -->
      <div class="formOsservazioni">

        <label for="osservazioni">Osservazioni</label><br>
        <textarea id="osservazioni" name="osservazioni" rows="5" style="width: 100%"
                v-model="form.remarks">
        </textarea>
      </div>

      <button type="button" @click="validerFormulaire()">Aggiornare</button>

      <Toast position="top-center" group="resultGroup" :style="{ 'width': '100%', 'max-width': '400px' }" />

    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import $ from "jquery";
import Toast from 'primevue/toast';

export default {
  name: "edit-confessori",
  components: { Toast },
  data() {
    return {
      form:   { id: 0, nome: '', cognome: '', immagine: '', tel: '', stanza: '', giurisdizione: '', 
              sitoweb: '', nascita: '', onomastico: '', professione: '', ordinazione: '', festa: '',
              email: '', cellulare: '', telfamiglia: '', telestero: '', telprovincia: '', orari: '',
              lingue: '', tipo: '', luogo: '', data_arrivo: '', info_arrivo: '', 
              data_partenza: '', info_partenza: '', remarks: '', attivo: 1 
      },
      errors: { nome: null, cognome: null, immagine: null, tel: null, stanza: null, giurisdizione: null, 
              sitoweb: null, nascita: null, onomastico: null, professione: null, ordinazione: null, festa: null,
              email: null, cellulare: null, telfamiglia: null, telestero: null, telprovincia: null, orari: null,
              lingue: null, tipo: null, luogo: null, data_arrivo: null, info_arrivo: null, 
              data_partenza: null, info_partenza: null, remarks: null, attivo: null 
      },
      mesi: [ "gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre" ],
      data: [],
      c: [],
      error: false,
      checkedValue: [],
    }
  },
  computed: {
    ...mapState(["annuarioDB", "annuarioDBOpen", "annuarioDBUpdate", "routerParams", "params", "parameters", "parametersOpen"]),
  },
  created() {
    // Si la DB n'est pas chargée c'est un appel direct à Edit et donc on sort redirigeant sur Annuario
    if (!this.annuarioDBOpen) {
      this.$router.push({ name: "Annuario" })
    }
    else { 
      if (!this.parametersOpen) {
        console.log('not parametersOpen') 
        var x = this.$store.dispatch("getParams")
        x.then( () => {
          this.fillFields();
          console.log('params après fillFields = ', this.$store.state.params )
        }) 
      } else {
        console.log('parameters are open')
        this.fillFields();
      }
    }
    $("html, body").animate({ scrollTop: 0 }, "slow"); // scroll to top of page
  },
  mounted() { this.$store.dispatch("setHighlight", { on: true }); }, // highlight the tab of tabMenu 
  unmounted() { this.$store.dispatch("setHighlight", { on: false }); }, // highlight the tab of tabMenu 

  methods: {
    languageClick (langue) { $("#lingua-" + langue).toggleClass("bold nonBold"); },
    capitalizeFirstLetter(string) {
      if (!string) return ""; // Handle empty or undefined strings
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    formatLangues(langues) {
      var l = langues.split('-');
      var tabLangues = [];
      Object.entries(l).forEach(([value]) => {
        tabLangues[l[value]] = this.$store.state.params['lingua'][l[value]];
      })
      return tabLangues;
    },
    fillFields() {
      // Remplissage des données de la DB dans les champs du Form
      this.form.id = this.annuarioDB[1][this.routerParams.noTab].id;
      this.form.nome = this.annuarioDB[1][this.routerParams.noTab].nome;
      this.form.cognome = this.annuarioDB[1][this.routerParams.noTab].cognome;
      this.form.immagine = this.annuarioDB[1][this.routerParams.noTab].immagine;
      this.form.tel = this.annuarioDB[1][this.routerParams.noTab].tel;
      this.form.luogo = this.annuarioDB[1][this.routerParams.noTab].luogo;
      this.form.tipo = this.annuarioDB[1][this.routerParams.noTab].tipo;
      this.form.stanza = this.annuarioDB[1][this.routerParams.noTab].stanza;
      this.form.giurisdizione = this.annuarioDB[1][this.routerParams.noTab].giurisdizione;
      this.form.sitoweb = this.annuarioDB[1][this.routerParams.noTab].sitoweb;
      this.form.email = this.annuarioDB[1][this.routerParams.noTab].email;
      this.form.cellulare = this.annuarioDB[1][this.routerParams.noTab].cellulare;
      this.form.nascita = this.annuarioDB[1][this.routerParams.noTab].nascita;
      this.form.professione = this.annuarioDB[1][this.routerParams.noTab].professione;
      this.form.ordinazione = this.annuarioDB[1][this.routerParams.noTab].ordinazione;
      this.form.onomastico = this.annuarioDB[1][this.routerParams.noTab].onomastico;
      this.form.festa = this.annuarioDB[1][this.routerParams.noTab].festa;
      this.form.telfamiglia = this.annuarioDB[1][this.routerParams.noTab].telfamiglia;
      this.form.telestero = this.annuarioDB[1][this.routerParams.noTab].telestero;
      this.form.telprovincia = this.annuarioDB[1][this.routerParams.noTab].telprovincia;
      this.form.orari = this.annuarioDB[1][this.routerParams.noTab].orari;
      this.form.lingue = this.annuarioDB[1][this.routerParams.noTab].lingue.split('-');
      this.form.data_arrivo = this.annuarioDB[1][this.routerParams.noTab].data_arrivo;
      this.form.info_arrivo = this.annuarioDB[1][this.routerParams.noTab].info_arrivo;
      this.form.data_partenza = this.annuarioDB[1][this.routerParams.noTab].data_partenza;
      this.form.info_partenza = this.annuarioDB[1][this.routerParams.noTab].info_partenza;
      this.form.remarks = this.annuarioDB[1][this.routerParams.noTab].remarks;
      this.form.attivo = this.annuarioDB[1][this.routerParams.noTab].attivo;
    },
    validateField(field) {
      this.error = false;
      if (field === 'nome') { this.errors.nome = this.form.nome.trim() ? null : 'Il NOME è obbligatorio'; }
      if (field === 'cognome') { this.errors.cognome = this.form.cognome.trim() ? null : 'Il COGNOME è obbligatorio'; }
    },
    dataFormat(laData) {
      var dN = laData.split("-");
      return ( parseInt(dN[2]) + " " + this.mesi[parseInt(dN[1]) - 1] + " " + dN[0] );
    },
    nomeDaEditare (cle) { return this.annuarioDB[1][cle].nome + " " + this.annuarioDB[1][cle].cognome ; },
    handleSubmit() { console.log('passe par handleSubmit') },
    validerFormulaire() {
      console.log('validerFormulaire exécuté')
      if ( this.errors.nome || this.errors.cognome ) {
        $("body, html").animate({ scrollTop: 0 }, "slow"); } // scroll to top of page
      else {

        console.log('le lingue da update = ', this.form.lingue)

        // Relève les langues sélectionnées et formatte
        var checkBoxElements = document.getElementsByClassName('lingueBox');
        var tiret = '';
        this.form.lingue = "";
        for (var i=0; checkBoxElements[i]; ++i){
              if (checkBoxElements[i].checked){
                this.form.lingue = this.form.lingue + tiret + checkBoxElements[i].value 
                tiret = "-";
              }
        }

        // Sélectionne du dropdown la catégorie (tipo) de confesseur
        var e = document.getElementById("selectCategoria");
        this.form.tipo = e.value;

        // Sélectionne du dropdown le lieu d'habitation du confesseur
        e = document.getElementById("selectLuogo");
        this.form.luogo = e.value;

        // Sélectionne du dropdown la chambre du confesseur
        e = document.getElementById("selectStanza");
        this.form.stanza = e.value;

        // Sélectionne du dropdown le jour fêté du frère (anniv ou onomastico)
        e = document.getElementById("selectFesta");
        this.form.festa = e.value;

        // Sélectionne le checkbox de l'actif
        this.form.attivo = document.getElementById('attivo').checked ? 1 : 0

        // Remplissage des données de la DB temporaire avec les champs du Form
        this.$store.commit( 'SET_ANNUARIO_DB_UPDATE', this.form )

        // exécute la mise à jour
        this.$store.dispatch("updateDBAnnuario").then(response => {
          if ( response == "passed" ) {
            this.$store.commit( 'SET_ANNUARIO_DB_UPDATE_MEMBER', { reparto: 1, member: this.routerParams.noTab, value: this.annuarioDBUpdate } )
            //this.annuarioDB[1][this.routerParams.noTab] = this.annuarioDBUpdate;
            this.$toast.add({
                severity: "success", 
                summary:  "AGGIORNAMENTO", 
                detail:   "La scheda di p. " + this.form.nome + " " + this.form.cognome + "è stata aggiornata.",
                position: 'bottom-center',
                group: 'resultGroup',
                life: 3000
              });
          }
          console.log('response dans edit = ', response);
          
        })
      }
    },
  },
};
</script>

<style>
  textarea { padding: 5px; }
  button { margin-bottom: 50px !important; }

  .flex-wrap {flex-wrap: wrap}

  .red { color: red; min-height: 20px; }
  #membriForm {
      margin: 5px;
      padding: 10px 0 10px 10px;
      border: 1px solid #ccc;
      border-radius: 8px;
      text-align: left;
  }
  *[class^="form"] { padding-right: 10px; }

  .formNome, .formCognome { min-width: 50%; }
  .formNascita, .formTelFamiglia { margin-bottom: 5px; }
  .formTelProvincia { margin-top: 5px; margin-bottom: 10px; }
  .formTel { width: 140px; }
  .formStanza { width: 325px; }
  .formGiurisdizione { width: 100%; }
  .formLingue { margin-top: 10px; }
  .formInfoArrivo, .formInfoPartenza { width: 100%; }
  .formOnomastico { max-width: 145px; margin-bottom: 10px; }
  .formOrdinazione, .formOnomastico { margin-top: 5px; }
  .formTipo, .formLuogo, .formTel, .formEmail, .formCellulare, 
     .formLingue, .formFesta, .formDataArrivo, .formInfoArrivo, 
     .formDataPartenza, .formInfoPartenza, .formStanza,
     .formGiurisdizione, .formSitoweb, .formAttivo { margin-bottom: 10px; }

  .orari { width: 100%; font-family: monospace; font-size: 12px;}
  .dropdown { min-height: 33px; border-radius: 3px; }

  .bold { font-weight: bold; }
  .nonBold { font-weight: 400; }

  label { font-weight: bold; }
  input[type="text"], input[type="tel"], input[type="date"] {
      width: 100%;
      display: block;
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
  }
  button {
      padding: 10px 15px;
      background-color: #007BFF;
      color: #fff;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      margin-bottom: 50px;
  }
  button:hover { background-color: #0056b3; }
</style>