<template>
  <div id="giubileo" v-if="annuarioDBOpen">
    <div class="surtitre" style="padding-top: 20px">
      <router-link to="/annuario/">Annuario</router-link>
    </div>
    <div class="titre-principal centre" style="padding-bottom: 20px">
      Confessori Aggiunti Giubileo
    </div>
    <div class="flex flex-space-between flex-align-end">
      <div class="flex flex-align-end">
        <div class="submenu ml5" @click="trier('pardate')">Per data</div>
        <div class="submenu ml5" @click="trier('parnom')">Per nome</div>
        <router-link to="/annuario/orariogiornaliero" style="line-height: 0.8;">
          <img class="submenuIcon" src="@/assets/img/icon_agenda.png" v-tooltip.top="'Orario giornaliero'" width="100" />
        </router-link>
        <router-link 
          :to="{ name: 'viewer', 
                 params: { linkID: 'orario_basilica/999999 - Orario Settimanale Basilica.pdf' }}"
          style="line-height: 0.8;"    
        >
          <img class="submenuIcon" src="@/assets/img/icon_pdf.png" v-tooltip.top="'PDF dell\'orario'" width="100" />
        </router-link>
        <router-link to="/annuario/collegio">
          <div class="submenu turc ml5">Collegio</div>
        </router-link>
      </div>
      <div style="margin-right: 8px;">
        <label id="attivo"><span>Solo attivi&nbsp;</span>
          <input class="attivoBox" type="checkbox" style="vertical-align: middle;" 
              :checked="this.attivo"
              @click="this.attivo = !this.attivo">
        </label>
      </div>
    </div>
    <div style="margin-bottom: 80px;">
      <template 
        v-for="(membro, index) in this.annuarioDB[1]" 
          :key="membro.immagine" 
          >
          <div class="list-center" v-if="(membro.tipo == 'agg' && this.attivo == true && membro.attivo == 1) || (membro.tipo == 'agg' && this.attivo == false )">
            <router-link
              :to="{
                name: 'SchedaAggiunto',
                params: { id: membro.immagine },
              }"
              @click="memberClicked(index)"
            >
              <Card :id="membro.data_arrivo">
                <template #content>
                  <div v-if="membro.data_arrivo != '9999-99-99'" class="dateArrivee" :id="membro.data_arrivo"></div>
                  <div class="flex flex-space-between">
                    <div class="flex flex-direction-column flex-center">
                      <div>
                        <strong>{{ membro.cognome.toUpperCase() }}</strong> {{ membro.nome }}
                      </div>
                      <div class="date-arrivee" v-if="membro.data_arrivo != '9999-99-99'">
                        <span style="color: white; font-weight: bold;">Arrivo: </span>
                        <span style="color: #ffc07c">{{ dataFormat(membro.data_arrivo) }}</span>
                      </div>
                    </div>
                    <div v-if="this.editable">
                      <img height="35" style="float: right; margin: 4px 5px 0 0" @click.stop.prevent="editClick(index, membro.immagine)" src="@/assets/img/buttonEdit.png">
                    </div>

                  </div>
                </template>
              </Card>
            </router-link>
          </div>
      </template>
    </div>
  </div>
</template>

<script>
import Card from "primevue/card";
import { mapState } from "vuex";
import $ from "jquery";

export default {
  name: "aggiunti-giubileo",
  components: { Card },
  data() {
    return {
      annuarioDisplay: {},
      mesi: ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"],
      data: [],
      lesParametres:{},
      attivo: true,
      editable: false,
      dateScrollFound: false,
      scrollDate: null,
    }
  },
  computed: {
    ...mapState(["annuarioDB", "annuarioDBOpen", "annuarioDBPending", "routerParams"]),
  },
  created() {
    console.log('annuariodb esiste ', this.annuarioDB)
    if (!this.annuarioDBOpen) {
      console.log("Annuario.vue mounted");
      this.$store.dispatch("getAnnuario");
    }
    $("html, body").animate({ scrollTop: 0 }, "slow"); // scroll to top of page
    console.log('refs1 = ', this.$refs)

    // Set the user level
    var theUserLevel = localStorage.getItem('user-level');
    console.log('user-level = ', theUserLevel); 
    this.editable = theUserLevel == "100" ? true : false; 
  },
  mounted() {
    this.$store.dispatch("setHighlight", { on: true }); // highlight the tab of tabMenu
    console.log('refs1 = ', this.$refs)
    console.log('el = ', this.$el)
  },
  unmounted() {
    this.$store.dispatch("setHighlight", { on: false }); // highlight the tab of tabMenu
  },
  methods: {
    memberClicked(memberIndex) {
      this.$schedaFrate = memberIndex;
      window.schedaFrate = memberIndex;
    },
    dataFormat(laData) {
      var dN = laData.split("-");
      return ( parseInt(dN[2]) + " " + this.mesi[parseInt(dN[1]) - 1] + " " + dN[0] );
    },
    trier(cle) {
      if ( cle == 'parnom' ) {
        this.data = this.annuarioDB[1].sort((a, b) => a.cognome.localeCompare(b.cognome)); }
      else {
        this.data = this.annuarioDB[1].sort((a, b) => a.data_arrivo.localeCompare(b.data_arrivo));
        var currentDate = new Date().toJSON().slice(0, 10);
        var slides = document.getElementsByClassName("dateArrivee");
        var arr = [].slice.call(slides); // convert HTMLColleciton to Array
        arr.sort((a, b) => a.id.localeCompare(b.id));
        // Recherche la date la plus proche après la date courante
        for (var i = 0; i < arr.length; i++) {
          if ( arr[i].id >= currentDate && !this.dateScrollFound ) {
            this.dateScrollFound = true;
            this.scrollDate = arr[i].id;
          }
        }
        let x = document.getElementById(this.scrollDate);
        // Effectue un saut à la prochaine date d'arrivée après la date courante après que le DOM soit complet
        this.$nextTick(() => {
          x.scrollIntoView({ behavior: "smooth", block: "start" });
        });
      }
    },
    editClick(numberTab, immagine) {
      console.log('edit cliqué');
      this.lesParametres = { 
        urlBack: '/annuario/giubileo',
        libelleBack: 'Giubileo Confessori Aggiunti Giubileo',
        noTab: numberTab
      }
      console.log('lesParametres = ', this.lesParametres)
      this.$store.commit( 'SET_ROUTER_PARAMS', this.lesParametres )

      this.$router.push({ name: "Edit", params: { id: immagine } })
      return false;
    }
  },
};
</script>

<style>
#giubileo .p-card,
#giubileo .p-card-body { border-radius: 10px !important; }
#giubileo .p-card-body { background-color: #6c8cbf; }
#giubileo .p-card-content { color: #fffa8f; font-size: 16px; line-height: 1.2em }
#giubileo .p-card-content .date-arrivee { font-size: 12px; font-weight: bold; }
#giubileo .list-center a { color: #fffa8f; }
#giubileo .p-card-content { text-align: left !important; padding: 0px !important; }
#giubileo .memberLabels { min-width: 140px; }

#giubileo .p-card .p-card-body { padding: 7px !important; }
.p-card  { margin: 5px; text-align: left }

.ml5 { margin-left: 5px; }
.ml10 { margin-left: 10px; }
.ml15 { margin-left: 15px; }

#giubileo .submenu { 
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  background-color: #7c0000ba;
  color: white;
  padding: 3px 9px;
  border-radius: 9px;
}
#giubileo .submenu.turc { background-color: #025563ba; }
#giubileo .submenuIcon { 
  cursor: pointer;
  margin-left: 5px;
  max-width: 30px;
}

</style>